import { makeStyles } from "tss-react/mui";

const clientsPaths: string[] = [
  "/images/clients/NewClients/abm.jpg",
  "/images/clients/NewClients/artes.jpg",
  "/images/clients/NewClients/at_osborne.png",
  "/images/clients/NewClients/besix.png",
  "/images/clients/NewClients/bfb.jpg",
  "/images/clients/NewClients/bpc.png",
  "/images/clients/NewClients/cegelec.jpg",
  "/images/clients/NewClients/cit_blaton.png",
  "/images/clients/NewClients/cle.jpg",
  "/images/clients/NewClients/dherte.png",
  "/images/clients/NewClients/eldw.png",
  "/images/clients/NewClients/franki.png",
  "/images/clients/NewClients/grand_est.jpg",
  "/images/clients/NewClients/houben.png",
  "/images/clients/NewClients/igretec.jpg",
  "/images/clients/NewClients/jdn.png",
  "/images/clients/NewClients/ores.jpg",
  "/images/clients/NewClients/parlementum_europeanum.png",
  "/images/clients/NewClients/rabot_dutilleul.png",
  "/images/clients/NewClients/rtbf.jpg",
  "/images/clients/NewClients/socatra.png",
  "/images/clients/NewClients/tpc.png",
  "/images/clients/NewClients/tpf.png",
  "/images/clients/NewClients/valens.png",
  "/images/clients/NewClients/vibes.png",
  "/images/clients/NewClients/vma.jpg",
];

const TestStyle = makeStyles({ name: "ClientsStyle" })((theme) => ({
  clientsComponent: {
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
    justifyContent: "center",
    alignItems: "center",
    width: "min(90%, 1000px)",
    img: {
      maxHeight: "75px",
      filter: "grayscale(100%)",
      maxWidth: "160px"
    },
  },
}));

function ClientsComponent() {
  const { classes } = TestStyle();

  function renderClients(clientsData: string[]) {
    let allClients: JSX.Element[] = [];
    let i = 0;
    for (const path of clientsData) {
      allClients.push(<img key={i} src={path} alt={path.slice(16, -4)}/>);
      i++;
    }

    return allClients;
  }

  return (
    <div className={classes.clientsComponent}>
      {renderClients(clientsPaths)}
    </div>
  );
}

export default ClientsComponent;
