import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";

import en from "./en/en.json";
import fr from "./fr/fr.json";
import nl from "./nl/nl.json";

export const defaultNS = "en";

export const resources = {
  en: {
    home: en.home,
    contact: en.contact,
    privacy: en.privacy,
    intro: en.intro,
    modal: en.modal,
    navbar: en.navbar,
    sideImgList: en.sideImgList,
    about: en.about,
    internships: en.internships,
    jobs: en.jobs,
    resources: en.resources,
    articles: en.articles,
    product: en.product,
    partners: en.partners
  },
  fr: {
    home: fr.home,
    contact: fr.contact,
    privacy: fr.privacy,
    intro: fr.intro,
    modal: fr.modal,
    navbar: fr.navbar,
    sideImgList: fr.sideImgList,
    about: fr.about,
    internships: fr.internships,
    jobs: fr.jobs,
    resources: fr.resources,
    articles: fr.articles,
    product: fr.product,
    partners: fr.partners
  },
  nl: {
    home: nl.home,
    contact: nl.contact,
    privacy: nl.privacy,
    intro: nl.intro,
    modal: nl.modal,
    navbar: nl.navbar,
    sideImgList: nl.sideImgList,
    about: nl.about,
    internships: nl.internships,
    jobs: nl.jobs,
    resources: nl.resources,
    articles: nl.articles,
    product: nl.product,
    partners: nl.partners
  },
};

const DETECTION_OPTIONS: { [key: string]: any } = {
  order: ["localStorage", "navigator"],
  lookupLocalStorage: "i18nextLng",
  caches: ["localStorage"],
};

i18next.use(LanguageDetector).use(Backend).use(initReactI18next).init({
  fallbackLng: "fr",
  resources,
  defaultNS,
  detection: DETECTION_OPTIONS,
  returnObjects: true,
});
