const mainStyle: { [key: string]: any } = {
  COLORS: {
    PRIMARY: "#BF1C19",
    PLIGHT: "#E05C3E",
    PVERYLIGHT: "#F19B5C",
    PMAXLIGHT: "#F4CC70",
    SECONDARY: "#B6B6B6",
    SLIGHT: "#E0E0E0",
    SVERYLIGHT: "#EDEDED",
    GREEN: "#B2CC64",
    PTEXT: "#555555",
    BGCONTACT: "#222222",
  },
  BREAKPOINTS: {
    XS: "320px",
    S: "480px",
    M: "768px",
    L: "1024px",
    XL: "1200px",
  },
  DESKTOP: {
    NAVBARHEIGHT: "105px",
    PFONTFAMILY: "Rubik",
    SFONTFAMILY: "Boogaloo",
    SIDEIMGGAP: "130px",
    FSH1: "60px",
    FSH2: "40px",
    FSH3: "28px",
    FSH4: "24px",
    FSH5: "18px",
    FSBIGBUTTONS: "24px",
  },
  MOBILE: {
    NAVBARHEIGHT: "105px",
    PFONTFAMILY: "Rubik",
    SFONTFAMILY: "Boogaloo",
    SIDEIMGGAP: "130px",
    FSH1: "35px",
    FSH2: "40px",
    FSH3: "20px",
    FSH4: "24px",
    FSH5: "18px",
    FSBIGBUTTONS: "24px",
  },
};

export default mainStyle;
