import React from 'react'
import Navbar from "../../Components/Navbar/Navbar";
import Contact from "../../Components/Contact/Contact";
import Intro from "../../Components/Intro/Intro";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

function Partners() {
    const { classes } = PartnersStyle();
    const partnersComponents = React.useRef<any>(renderPartners(partnersData));
    const { t } = useTranslation("partners");
    const introSize = window.screen.width >= 768 ? 0 : 2

    function renderPartners(partnersData: any[]): any[][] {
        const PartnersOutput:any[] = [];
    
        for (let index = 0; index < partnersData.length; index++) {
            const cPartner = partnersData[index];
            const cPartnerOutput:any[] = [];
    
            if (cPartner.adress.length > 0) {
                const adresses = [];
                for (let j = 0; j < cPartner.adress.length; j++) {
                    const cAdress = cPartner.adress[j];
                    adresses.push(<p>{cAdress}</p>);
                }
                cPartnerOutput.push(...adresses)
            }
    
            if (cPartner.mail !== "") cPartnerOutput.push(<span>mail: {cPartner.mail}</span>)
            if (cPartner.mobile !== "") cPartnerOutput.push(<span>{cPartner.mobile}</span>)
            let ImageItem;

            if (cPartner.url) ImageItem = <div className={classes.partnerImage}><a href={cPartner.url} rel="noreferrer" target="_blank"><img alt="Company Logo" src={cPartner.image}/></a></div>;
            else ImageItem = <div className={classes.partnerImage}><img alt="Company Logo" src={cPartner.image}/></div>;

            PartnersOutput.push(<>{ImageItem} <div className={classes.partnerInfo}>{cPartnerOutput}</div></>);
        }
    
        return PartnersOutput;
    }

    return (
        <div>
            <Navbar />
            <Intro type="article12" size={introSize} FSH1="40px" FSH2="20px" title={t("title")} subtitle=""/>
            <div className={classes.partnersContainer}>
                {partnersComponents.current.map((cParterComponent: any) => {
                    return <div className={classes.partnerContainer}>{cParterComponent}</div>
                })}
            </div>
            <Contact />
        </div>
    )
}

export default Partners

const PartnersStyle = makeStyles({ name: "PartnersStyle" })((theme) => ({ 
    partnersContainer: {
        margin: "60px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 56,
    },
    partnerContainer:{
        display: "flex",
        width: "80vw",
        maxWidth: 800,
        justifyContent: "space-between"
    },
    partnerImage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 300
    },
    partnerInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        width: 280
    }
  }));



const partnersData = [
    {
        adress : ["BATir", "Campus Solbosch - Bâtiment C", "Avenue Adolphe Buyl, 87 - CP 194/2 - 1050 Bruxelles"],
        mail : "batir@ulb.be",
        mobile: "",
        url: "https://batir.polytech.ulb.be/researchunits/aia",
        image: "/images/partners/logo_ulb.png"
    },
    {
        adress: ["43-201 Hiranotoriimaecho, Kita-ku", "Kyoto-City, Kyoto 603-8321 Japan"],
        mail: "takehiko.kato@cato-systems.com",
        mobile: "+81-90-7751-5438",
        url: "",
        image: "/images/partners/logo_cato.png"
    },
    {
        adress: ["Contech Digital Consulting"],
        mail: "jason.ruddle@contech-digital.com",
        mobile: "07757 213243",
        url: "https://www.linkedin.com/company/contech-digital-consulting/about/",
        image: "/images/partners/logo_contech.png"
    },
    {
        adress: ["8 Allée André Bouloche 26100", "Romans-sur-Isère, France"],
        mail: "contact@flexbim5D.com",
        mobile: "+33 7 67 93 06 95 ",
        url: "https://www.flexbim5d.com/",
        image: "/images/partners/logo_flexbim.png"
    },
    {
        adress: [],
        mail: "pascal.boulee@practicalbim.fr",
        mobile: "06 98 46 48 38",
        url: "https://www.linkedin.com/company/practical-bim",
        image: "/images/partners/logo_practicalbim.png"
    },
    {
        adress: ["195, rue de Differdange", "L-4437 Soleuvre"],
        mail: "info@quadram.lu",
        mobile: "+352 58 22 16 1",
        url: "https://quadram.online/",
        image: "/images/partners/logo_quadram.png"
    },
    {
        adress: [],
        mail: "hello@smartbeam.co",
        mobile: "+32 2 235 14 24",
        url: "https://www.smartbeam.co",
        image: "/images/partners/logo_smartbeam.png"
    },
]
