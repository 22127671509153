import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const clientsData: { [key: string]: any }[] = [
  {
    img: "/images/clients/icon_person_1.webp",
    text: "Gestionnaires de projets",
  },
  {
    img: "/images/clients/icon_person_2.webp",
    text: "Entrepreneurs généraux",
  },
  {
    img: "/images/clients/icon_person_3.webp",
    text: "Propriétaires de biens immobiliers",
  },
];

const TestStyle = makeStyles({ name: "ForAllStyle" })((theme) => ({
  forAllComponent: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    justifyContent: "center",
    width: "min(1200px, 80%)",
    margin: "0 auto",
  },

  textInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    textAlign:'center',

    h3: {
      fontSize: theme.desktop.FSH3
    },

    a: {
      fontWeight: "800",
      color: theme.colors.primary.PRIMARY,
      textDecoration: "none"
    },
    p: {
      color: theme.colors.primary.PTEXT,
    },
  },

  clients: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    rowGap: "20px",
    textAlign:'center'
  },
  client: {
    width: "max(200px, 30%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    img: {
      width: "150px",
      height: "150px",
      borderRadius: "50%",
    },

    a: {
      textDecoration: "none",
      color: theme.colors.primary.PRIMARY,
    },
  },
}));

function ForAll() {
  const { classes } = TestStyle();
  const { t } = useTranslation("home");

  function renderClients() {
    let allClients: JSX.Element[] = [];
    for (let i = 0; i < clientsData.length; i++) {
      allClients.push(
        <div key={i} className={classes.client}>
          <img src={clientsData[i].img} alt="Client Illustration" />
          <Link to="/portfolio">{t(`forAll.photoContent.${i}` as any)}</Link>
        </div>
      );
    }
    return allClients;
  }

  return (
    <div className={classes.forAllComponent}>
      <div className={classes.textInfo}>
        <h3>{t("forAll.title")}</h3>
        <p>{t("forAll.content")}</p>
        <Link to="/portfolio">{t("forAll.seeMore")}</Link>
      </div>
      <div className={classes.clients}>{renderClients()}</div>
    </div>
  );
}

export default ForAll;
