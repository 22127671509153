import { makeStyles } from "tss-react/mui";
import { DropdownMenu, DropdownMenuLang } from "../DropdownMenu/DropdownMenu";
import TrialModal from "../TrialModal/TrialModal";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { keyframes } from "tss-react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

const TestStyle = makeStyles({ name: "NavbarStyle" })((theme, realHeight:any) => ({
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "fixed",
    zIndex: "10",
    width: "100%",
    height: theme.desktop.NAVBARHEIGHT,
    backgroundColor: "white",
    paddingRight: "30px",
    ".logo": {
      paddingLeft: "10px",
      img: {
        height: "auto",
        width: "140px",
      },
    },
    [`@media screen and (max-width:${theme.breakpoints.NAVBAR})`]: {
      height: theme.mobile.NAVBARHEIGHT,
    },

    ul: {
      height: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "2vw",
      listStyle: "none",
      whiteSpace: "nowrap",
      li: { 
        textAlign: "center",
        letterSpacing:"0.05em",
        "> a": {
          textDecoration: "none",
          color: "dimgrey",
          fontSize : theme.desktop.FSNB,
        },
      },

      [`@media screen and (max-width:${theme.breakpoints.NAVBAR})`]: {
        display: "none",
        gap:"unset",
        position: "absolute",
        flexDirection: "column",
        height: `calc(100vh - ${theme.mobile.NAVBARHEIGHT})`,
        right: "0",
        top: theme.mobile.NAVBARHEIGHT,
        padding: `5% 0 calc(5% + 100vh - ${realHeight})`,
        alignItems: "center",
        justifyContent: "space-around",
        width: "0",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        backdropFilter: "blur(7px)",
        textAlign: "center",

        li: {
          width: "80%",
          a: {
            fontSize : theme.mobile.FSNB,
          },
          [`@media screen and (max-width:${theme.breakpoints.S})`]: {
            maxWidth: "150px",
          }
        },
      },
    },

    ".showedNavMenu": {
      display: "flex",
      width: "30%",
      [`@media screen and (max-width:${theme.breakpoints.S})`]: {
        width: "100%",
      },

      animation: `${keyframes`
                    100% {
                        transform: translateX(0%);
                        -webkit-transform: translateX(0%);
                    }
                    0% {
                        transform: translateX(100%);
                        -webkit-transform: translateX(100%);
                    }`} .5s ease`,
      animationFillMode: "forwards",
    },

    ".hideNavMenu" : {
      display: "flex",
      width:'30%',
      [`@media screen and (max-width:${theme.breakpoints.S})`]: {
        width: "100%",
      },
      [`@media screen and (max-width:${theme.breakpoints.NAVBAR})`]: {
        animation: `${keyframes`
                      0% {
                          transform: translateX(0%);
                          -webkit-transform: translateX(0%);
                      }
                      95% {
                          transform: translateX(100%);
                          -webkit-transform: translateX(100%);
                      }
                      100% {
                        width:0;
                      }`} .5s ease-out`,
        animationFillMode: "forwards",
      },
    },

    ".dropbtn": {
      display: "none",
      backgroundColor: "Transparent",
      border: "none",
      ".icon": {
        scale: "1.5",
      },

      [`@media screen and (max-width:${theme.breakpoints.NAVBAR})`]: {
        display: "block",
      },
    },
  },
  modalContainer : {
    button : {
      fontSize: theme.desktop.FSNB,
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      borderRadius: "20px",
      color: "white",
      cursor: "pointer",
      backgroundColor: theme.colors.primary.PRIMARY,
      whiteSpace: "nowrap",
      border:"none"
    },

    [`@media screen and (min-width:${theme.breakpoints.NAVBAR})`]: {
      marginLeft:'25px'
    },
    [`@media screen and (max-width:${theme.breakpoints.NAVBAR})`]: {
      button : {
        fontSize: theme.mobile.FSNB,
      }
    },
  }
}));

function Navbar() {
  const realHeight = `${window.innerHeight}px`;
  const { classes } = TestStyle(realHeight as any);
  const { t } = useTranslation("navbar");
  const [showNavMenu, setShowNavMenu] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //This function + showNavMenu as Number instead of Boolean for an animation when we hide the navMenu
  // AND to not have an animation on page load
  function handleClick() {
    if(showNavMenu === 2){setShowNavMenu(1)}
    else {setShowNavMenu(showNavMenu + 1)}
  }

  return (
    <nav className={classes.navbar}>
      <Link to="/" className="logo">
        <img alt="Kabandy_Logo" src="/images/logo/logo_with_padding.png" />
      </Link>
      <ul className={showNavMenu === 0 ? "" : (showNavMenu === 1 ? "showedNavMenu" : "hideNavMenu")}>
        <li>
          <Link to="/product">{t("product")}</Link>
        </li>
        <li>
          <DropdownMenu name="solutions" />
        </li>
        <li>
          <Link to="/portfolio">{t("portfolio")}</Link>
        </li>
        <li>
          <Link to="/partners">{t("partners")}</Link>
        </li>
        <li>
          <DropdownMenu name="resources" />
        </li>
        <li>
          <DropdownMenu name="about" />
        </li>
        <li>
          <a href="https://app.kabandy.com" target="_blank" rel="noreferrer">{t("login")}</a>
        </li>
        <li className={classes.modalContainer}>
          <button onClick={() => setIsModalOpen(!isModalOpen)} aria-label="NavbarTrialModal">{t("trial")}</button>
          {isModalOpen && <TrialModal onClose={() => setIsModalOpen(false)} type="home" />}
        </li>
        <li>
          <DropdownMenuLang name="langs" />
        </li>
      </ul>
      <button className="dropbtn" onClick={handleClick} aria-label="SideNavbarButton">
        {showNavMenu % 2 ? <CloseIcon className="icon" /> : <MenuIcon className="icon" />}
      </button>
    </nav>
  );
}

export default Navbar;
