import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./Pages/HomePage/Home";
import Product from "./Pages/ProductPage/Product";
import Solutions from "./Pages/SolutionsPage/Solutions";
import Portfolio from "./Pages/PortfolioPage/Portfolio";
import Ressources from "./Pages/RessourcesPage/Ressources";
import About from "./Pages/AboutPage/About";
import Jobs from "./Pages/JobsPage/Jobs";
import Internships from "./Pages/InternshipsPage/Internships";
import ArticleSwitch from "./Pages/ArticlesPage/ArticleSwitch";
import Privacy from "./Pages/PrivacyPage/Privacy";
import Webinars from "./Pages/WebinarsPage/Webinars";
import Partners from "./Pages/PartnersPage/Partners"
import "./i18n/config";
import { ScrollToTop } from "./Utilities/HelpfulFunctions";
import { useEffect } from "react";
import ReactGA from "react-ga4";


function App() {
  const location = useLocation()
  useEffect(() => {
    if (location.pathname !== '/') {
      ReactGA.send({
        hitType: 'pageview', 
        page: location.pathname,
        title: location.pathname.split("/")[1]
      })
    } 
  }, [location])
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/trial" element={<Home freetrial={true}/>}></Route>
        <Route path="/freetrial/*" element={<Home freetrial={true}/>}></Route>
        <Route path="/product" element={<Product />}></Route>
        <Route path="/solutions" element={<Solutions />}></Route>
        <Route path="/portfolio" element={<Portfolio />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/ressources" element={<Ressources />}></Route>
        <Route path="/webinars" element={<Webinars />}></Route>
        <Route path="/webinars/:webinarId" element={<Webinars />}></Route>
        <Route path="/jobs" element={<Jobs />}></Route>
        <Route path="/partners" element={<Partners />}></Route>
        <Route path="/internships" element={<Internships />}></Route>
        <Route
          path="/article_page_:article_id"
          element={<ArticleSwitch />}
        ></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/*" element={<Home />}></Route>
      </Routes>
    </>
  );
}

export default App;
