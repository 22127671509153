const PortfolioItemData: { [key: string]: any } = [
  {
    title: "Bavière building",
    content: "Architects: Assar Architects",
    img: "/images/portfolio/portfolio-1.jpg.webp",
  },
  {
    title: "European Parliament HQ",
    content: "Photo credits: John Thys / AFP",
    img: "/images/portfolio/portfolio-2.jpg.webp",
  },
  {
    title: "Les Entreprises Louis De Waele",
    content: "Architects: MA²",
    img: "/images/portfolio/portfolio-3.jpg.webp",
  },
  {
    title: "ORES HQ",
    content: "Architects: BAEV",
    img: "/images/portfolio/portfolio-4.jpg.webp",
  },
  {
    title: "RTBF Media Square",
    content: "Architects: MDW Architects, V+",
    img: "/images/portfolio/portfolio-5.jpg.webp",
  },
  {
    title: "Solvay HQ",
    content: "Architects: Schmidt Hammer Lassen Architects",
    img: "/images/portfolio/portfolio-6.jpg.webp",
  },
  {
    title: "Dockin",
    content: "Architects: Reservoir A",
    img: "/images/portfolio/portfolio-7.jpg.webp",
  },
  {
    title: "Rue du Midi, In Advance",
    content: "Architects: Skope",
    img: "/images/portfolio/portfolio-8.jpg.webp",
  },
  {
    title: "Bioscience KUL, CIT Blaton",
    content: "Architects: SVR Architects, Proof of the sum",
    img: "/images/portfolio/portfolio-9.jpg.webp",
  },
];

export default PortfolioItemData;
