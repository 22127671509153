import { makeStyles } from "tss-react/mui";
import PortfolioItemData from "./PortfolioItemData";

interface Props {
  number: number;
}

const TestStyle = makeStyles({ name: "PortfolioItemStyle" })(
  (theme, props: any) => ({
    portfolioItem: {
      backgroundImage: `url(${PortfolioItemData[props.number].img})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
    },

    portfolioText: {
      opacity: "0",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      gap: "15px",
      paddingBottom: "10%",
      paddingLeft: "10%",
      backgroundColor: "rgba(255,255,255,0.7)",
      transition: ".3s ease-out",

      "&:hover": {
        opacity: "1",
      },

      h5: {
        color: theme.colors.primary.PRIMARY,
        fontSize: theme.desktop.FSH5,
      },
    },
  })
);

function PortfolioItem(props: Props) {
  const { classes } = TestStyle(props as any);
  return (
    <div className={classes.portfolioItem}>
      <div className={classes.portfolioText}>
        <h5>{PortfolioItemData[props.number].title}</h5>
        <p>{PortfolioItemData[props.number].content}</p>
      </div>
    </div>
  );
}

export default PortfolioItem;
