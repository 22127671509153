import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

interface Props {
  number: number;
}

const dataCard: { [key: string]: any } = [
  <ThumbUpIcon className={"icon"} />,
  <AltRouteIcon className={"icon"} />,
  <SettingsSuggestIcon className={"icon"} />,
];

const IconCardStyle = makeStyles({ name: "IconCardStyle" })((theme) => ({
  iconComponent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "min(40%, 300px)",
    textAlign: "center",
    paddingBottom: "15px",
    ".icon": {
      fontSize: "40px",
    },
    h4: {
      fontSize: theme.desktop.FSH4,
      fontWeight:'600'
    },

    [`@media screen and (max-width:${theme.breakpoints.S})`]: {
      width: "80%",
    },
  },
}));

function IconCard(props: Props) {
  const { classes } = IconCardStyle();
  const { t } = useTranslation("home");
  return (
    <div className={classes.iconComponent}>
      {dataCard[props.number]}
      <h4>{t(`factsIcons.${props.number}.title` as any)}</h4>
      <p>{t(`factsIcons.${props.number}.content` as any)}</p>
    </div>
  );
}

const dataTable: { [key: string]: any } = [
  "/images/home/intro_icon_1.svg",
  "/images/home/intro_icon_2.svg",
  "/images/home/intro_icon_3.svg",
  "/images/home/intro_icon_4.svg",
  "/images/home/intro_icon_5.svg",
  "/images/home/intro_icon_6.svg",
];

const IconTableStyle = makeStyles({ name: "IconTableStyle" })((theme) => ({
  iconComponent: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    textAlign: "center",
    width: "70%",
    gap: "15px",

    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      width: "80%",
    },

    [`@media screen and (max-width:${theme.breakpoints.S})`]: {
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "repeat(3, 1fr)",
    },

    div: {
      img: {
        width: "60%",
        maxWidth: "80px",
      },
    },
  },
}));

function IconTable() {
  const { classes } = IconTableStyle();
  const { t } = useTranslation("home");

  function renderIconItem() {
    let arrayOfIcons: JSX.Element[] = [];
    const nbOfIcons = t("tableContent" as any).length;

    for (let i = 0; i < nbOfIcons; i++) {
      arrayOfIcons.push(
        <div key={i}>
          <img src={dataTable[i]} alt={`Icon_${i}`}></img>
          <p>{t(`tableContent.${i}` as any)}</p>
        </div>
      );
    }
    return arrayOfIcons;
  }

  return <div className={classes.iconComponent}>{renderIconItem()}</div>;
}

export { IconCard, IconTable };
