import { createTheme } from "@mui/material/styles";

//Theme for Material UI. Based mostly on the red kabandy and default material design options
const MaterialUiTheme = createTheme({
  colors: {
    primary: {
      PRIMARY: "#BF1C19",
      PLIGHT: "#E05C3E",
      PVERYLIGHT: "#F19B5C",
      PMAXLIGHT: "#F4CC70",
      GREEN: "#B2CC64",
      PTEXT: "#555555",
      BGCONTACT: "#222222",
    },
    secondary: {
      SECONDARY: "#B6B6B6",
      SLIGHT: "#E0E0E0",
      SVERYLIGHT: "#EDEDED",
    },
  },

  breakpoints: {
    XS: "320px",
    S: "480px",
    M: "768px",
    NAVBAR:"900px",
    L: "1024px",
    XL: "1200px",
  },
  fontType: {
    PRIMARY: "'Rubik', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    SECONDARY: "Boogaloo",
  },

  desktop: {
    NAVBARHEIGHT: "105px",
    SIDEIMGGAP: "50px",
    MAINCONTAINERGAP: "100px",
    FSH1: "60px",
    FSH2: "40px",
    FSH3: "28px",
    FSH4: "24px",
    FSH5: "18px",
    FSH6: "14px",
    FSBIGBUTTONS: "24px",
    FSNB: "15px",
  },
  mobile: {
    NAVBARHEIGHT: "85px",
    SIDEIMGGAP: "60px",
    MAINCONTAINERGAP: "80px",
    FSH1: "35px",
    FSH2: "25px",
    FSH3: "20px",
    FSH4: "18px",
    FSH5: "14px",
    FSH6: "12px",
    FSBIGBUTTONS: "24px",
    FSNB: "18px",
  },
});

export default MaterialUiTheme;
