import { makeStyles } from "tss-react/mui";
import Navbar from "../../Components/Navbar/Navbar";
import Contact from "../../Components/Contact/Contact";
import { Trans, useTranslation } from "react-i18next";

const TestStyle = makeStyles({ name: "PrivacyStyle" })((theme) => ({
  content: {
    padding: "130px 10% 50px 5%",
    display: "flex",
    flexDirection: "column",
    gap: "40px",

    a: {
      textDecoration: "none",
    },
  },
  parts: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    color: theme.colors.primary.PTEXT,

    h3: {
      fontSize: theme.desktop.FSH3,
      color: "black",
    },
    ul: {
      paddingLeft: "40px",
      li: {
        color: theme.colors.secondary.SECONDARY,
      },
    },
  },
}));

function Privacy() {
  const { classes } = TestStyle();
  const { t } = useTranslation("privacy");
  return (
    <div>
      <Navbar />
      <div className={classes.content}>
        <div className={classes.parts}>
          <h3>{t("titles.0" as any)}</h3>
          <p>
            <Trans i18nKey={"specialContent.0" as any} t={t}>
              A
              <a
                href="https://ec.europa.eu/info/law/law-topic/data-protection_en"
                target="_blank"
                rel="noreferrer"
              >
                B
              </a>
              C
            </Trans>
          </p>
        </div>
        <div className={classes.parts}>
          <h3>{t("titles.1" as any)}</h3>
          <p>{t("content.paragraph.0" as any)}</p>
          <ul>
            <li>{t("content.liItems.0.0" as any)}</li>
            <li>{t("content.liItems.0.1" as any)}</li>
            <li>{t("content.liItems.0.2" as any)}</li>
            <li>{t("content.liItems.0.3" as any)}</li>
            <li>{t("content.liItems.0.4" as any)}</li>
          </ul>
          <p>{t("content.paragraph.1" as any)}</p>
        </div>
        <div className={classes.parts}>
          <h3>{t("titles.2" as any)}</h3>
          <p>{t("content.paragraph.2" as any)}</p>
          <ul>
            <li>{t("content.liItems.1.0" as any)}</li>
            <li>
              <li>
                <Trans i18nKey={"content.liItems.1.1" as any} t={t}>
                  A<i> B</i>C <i>D</i>E
                </Trans>
              </li>
            </li>
            <li>{t("content.liItems.1.2" as any)}</li>
            <li>{t("content.liItems.1.3" as any)}</li>
            <li>{t("content.liItems.1.4" as any)}</li>
            <li>{t("content.liItems.1.5" as any)}</li>
          </ul>
          <p>
            <Trans i18nKey={"specialContent.1" as any} t={t}>
              A<a href="mailto:rajan.filomeno.coelho@kabandy.com">B</a>
            </Trans>
          </p>
        </div>
        <div className={classes.parts}>
          <h3>{t("titles.3" as any)}</h3>
          <p>{t("content.paragraph.2" as any)}</p>
        </div>
        <div className={classes.parts}>
          <h3>{t("titles.4" as any)}</h3>
          <p>{t("content.paragraph.2" as any)}</p>
        </div>
      </div>

      <Contact />
    </div>
  );
}

export default Privacy;
