import { makeStyles } from "tss-react/mui";
import SideImg from "../../Components/SideImg/SideImg";
import Intro from "../../Components/Intro/Intro";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const article1Data: string[] = [
  "/images/articles/1/01_Auto-select.gif",
  "/images/articles/1/02_Units.gif",
  "/images/articles/1/03_Similar-objects.gif",
  "/images/articles/1/04_Tablet-mode.gif",
  "/images/articles/1/05_Auto-numbering.gif",
  "/images/articles/1/06_Add-column.gif",
  "/images/articles/1/07_Real-time-board_.gif",
  "/images/articles/1/08_Activity.gif",
  "/images/articles/1/09_Model-versioning.gif",
  "/images/articles/1/10_Mention.gif",
  "/images/articles/1/11_Notification.gif",
  "/images/articles/1/12_Who-is-connected.gif"
];

const TestStyle = makeStyles({ name: "Article1Style" })(
  (theme, props: any) => ({
    content: {
      display: "flex",
      flexDirection: "column",
      margin: "70px auto",
      gap: "80px",
      width:'80%',
      maxWidth:'900px',

      "ul, ol": {
        paddingLeft: "20px",
      },
    },

    contentPart: {
      display: "flex",
      flexDirection: "column",
      gap: "30px",
      "p, li" : {
        lineHeight:'1.5',
        color : theme.colors.primary.PTEXT
      },
      h3: {
        color: theme.colors.primary.PRIMARY,
        textAlign: "center",
        fontSize: theme.desktop.FSH3,
        marginBottom: "10px",
        //textTransform: "lowercase",
        "&:first-letter" : {          
          textTransform: "uppercase"      
        }
      },
      figure : {
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        gap:'5px',
        img : {width:'90%'},
        figcaption : {
          width:'80%',
          textAlign:'center',
          color:'gray'
        }
      },
      img:{
        margin: '0 auto',
        maxWidth:'90%',
      },

      [`@media screen and (max-width:${theme.breakpoints.M})`]: {
        h3: {
          fontSize: theme.mobile.FSH3,
        },
      },
    },

    contentPartFlex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "40px",
      a: {
        display: "flex",
        justifyContent: "center",
        width: "30%",
        img: {
          width: "100%",
          maxWidth: "300px",
        },
      },
      p: {
        lineHeight:'1.5',
        width: "70%",
        color : theme.colors.primary.PTEXT

      },
      [`@media screen and (max-width:${theme.breakpoints.M})`]: {
        flexDirection: "column",
        alignItems: "center",
        a: {
          width: "80%",
          img: {
            width: "100%",
            maxWidth: "400px",
          },
        },
        p: {
          width: "100%",
        },
      },
    },
  })
);

function Article1() {
  const { classes } = TestStyle();

  function renderArticle() {
    let arrayOfSideImg: JSX.Element[] = [];
    for (let i = 0; i < article1Data.length; i++) {
      arrayOfSideImg.push(
        <SideImg
          key={i}
          number={i}
          page="articles"
          pagenb={1}
          img={article1Data[i]}
        />
      );
    }
    return arrayOfSideImg;
  }

  return (
    <div>
      <Intro type="article1" size={2} />
      <div style={{maxWidth:'1200px'}} className={classes.content}>{renderArticle()}</div>
    </div>
  );
}

function Article2() {
  const { classes } = TestStyle();
  const { t } = useTranslation("articles");

  return (
    <div>
      <Intro type="article2" size={2} />
      <div className={classes.content}>
        <div className={classes.contentPart}>
          <h3>{t("article2.part1.title" as any)}</h3>
          <p>{t("article2.part1.content.0" as any)}</p>
          <p>{t("article2.part1.content.1" as any)}</p>
          <p>{t("article2.part1.content.2" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article2.part2.title" as any)}</h3>
          <p>{t("article2.part2.content.0" as any)}</p>
          <p>{t("article2.part2.content.1" as any)}</p>
          <p>{t("article2.part2.content.2" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article2.part3.title" as any)}</h3>
          <p>{t("article2.part3.content.0" as any)}</p>
          <p>{t("article2.part3.content.1" as any)}</p>
          <p>{t("article2.part3.content.2" as any)}</p>
          <p>{t("article2.part3.content.2" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article2.part4.title" as any)}</h3>
          <p>{t("article2.part4.content.0" as any)}</p>
        </div>
      </div>
    </div>
  );
}

function Article3() {
  const { classes } = TestStyle();
  const { t } = useTranslation("articles");

  return (
    <div>
      <Intro type="article3" size={2} />
      <div className={classes.content}>
        <div className={classes.contentPart}>
          <p>{t("article3.part1.content.0" as any)}</p>
          <p>{t("article3.part1.content.1" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article3.part2.title" as any)}</h3>
          <p>{t("article3.part2.content.0" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article3.part3.title" as any)}</h3>
          <p>{t("article3.part3.content.0" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article3.part4.title" as any)}</h3>
          <p>{t("article3.part4.content.0" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article3.part5.title" as any)}</h3>
          <p>{t("article3.part5.content.0" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article3.part6.title" as any)}</h3>
          <p>{t("article3.part6.content.0" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article3.part7.title" as any)}</h3>
          <p>{t("article3.part7.content.0" as any)}</p>
          <p>{t("article3.part7.content.1" as any)}</p>
          <p>{t("article3.part7.content.2" as any)}</p>
          <p>{t("article3.part7.content.3" as any)}</p>
        </div>
      </div>
    </div>
  );
}

function Article4() {
  const { classes } = TestStyle();
  const { t } = useTranslation("articles");

  return (
    <div>
      <Intro type="article4" size={2} />
      <div className={classes.content}>
        <div className={classes.contentPart}>
          <p>{t("article4.content.0" as any)}</p>
        </div>
        <div className={classes.contentPartFlex}>
          <a href="https://www.smartbeam.co">
            <img src="/images/articles/4/logo_smartbeam.webp" alt="Smartbeam"></img>
          </a>
          <p>{t("article4.content.1" as any)}</p>
        </div>
        <div className={classes.contentPartFlex}>
          <a href="https://cooperlink.io">
            <img src="/images/articles/4/logo_cooperlink.webp" alt="Cooperlink"></img>
          </a>
          <p>{t("article4.content.2" as any)}</p>
        </div>
      </div>
    </div>
  );
}

function Article5() {
  const { classes } = TestStyle();
  const { t } = useTranslation("articles");

  return (
    <div>
      <Intro type="article5" size={2} />
      <div className={classes.content}>
        <div className={classes.contentPart}>
          <h3>{t("article5.part1.title" as any)}</h3>
          <p>
            <Trans t={t} i18nKey={"article5.part1.content.0" as any}>
              PreB<b>In B</b>Post B
            </Trans>
          </p>

          <p>
            <Trans t={t} i18nKey={"article5.part1.content.1" as any}>
              PreB<b>In B</b>Post B
            </Trans>
          </p>
          <p>
            <Trans t={t} i18nKey={"article5.part1.content.2" as any}>
              PreB<b>In B</b>Post B
            </Trans>
          </p>
          <img src="/images/articles/5/Automation_in_Construction_Traditional_process.gif" alt="AnimatedIllustration"></img>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article5.part2.title" as any)}</h3>
          <p>
            <Trans t={t} i18nKey={"article5.part2.content.0" as any}>
              PreB<b>In B</b>Post B
            </Trans>
          </p>
          <p>{t("article5.part2.content.1" as any)}</p>
          <ul>
            <li>
              <Trans t={t} i18nKey={"article5.part2.content.2" as any}>
                PreB<b>In B</b>Post B
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey={"article5.part2.content.3" as any}>
                PreB<b>In B</b>Post B
              </Trans>
            </li>
          </ul>
          <img src="/images/articles/5/Automation_in_Construction_Automation_process.gif" alt="AnimatedIllustration"></img>
          <p>{t("article5.part2.content.4" as any)}</p>
          <p>
            <Trans t={t} i18nKey={"article5.part2.content.5" as any}>
              PreB<b>In B</b>Post B
            </Trans>
          </p>
          <p>{t("article5.part2.content.6" as any)}</p>
          <img src="/images/articles/5/Automation_in_Construction_Link_Board_Viewer.gif" alt="AnimatedIllustration"></img>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article5.part3.title" as any)}</h3>
          <p>
            <Trans t={t} i18nKey={"article5.part3.content.0" as any}>
              PreB<b>In B</b>Post B
            </Trans>
          </p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article5.part4.title" as any)}</h3>
          <p>
            <Trans t={t} i18nKey={"article5.part4.content.0" as any}>
              A
              <a href="https://www.linkedin.com/in/rajanfilomenocoelho/">
                In Link
              </a>
              Post Link
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
}

function Article6() {
  const { classes } = TestStyle();
  const { t } = useTranslation("articles");

  return (
    <div>
      <Intro type="article6" size={2} />
      <div className={classes.content}>
        <div className={classes.contentPart}>
          <h3>{t("article6.part1.title" as any)}</h3>
          <p>{t("article6.part1.content.0" as any)}</p>
          <p>{t("article6.part1.content.1" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article6.part2.title" as any)}</h3>
          <p>
            <Trans t={t} i18nKey={"article6.part2.content.0" as any}>
              PreLink
              <a href="https://kabandy.com" target="_blank" rel="noreferrer">
                In Link
              </a>
              Post Link PreLink
              <a href="https://www.smartbeam.co" target="_blank" rel="noreferrer">
                In Link
              </a>
              Post Link
            </Trans>
          </p>
          <img src="/images/articles/6/Work_Progress_Reports.png" alt="WorkProgress"></img>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article6.part3.title" as any)}</h3>
          <p>{t("article6.part3.content.0" as any)}</p>
          <img src="/images/articles/6/Kabandy-Smartbeam1.gif" alt="AnimatedIllustration"></img>
          <p>{t("article6.part3.content.1" as any)}</p>
          <img src="/images/articles/6/Kabandy-Smartbeam2.gif" alt="AnimatedIllustration"></img>
          <img src="/images/articles/6/Smartbeam1.gif" alt="AnimatedIllustration"></img>
          <p>{t("article6.part3.content.2" as any)}</p>
          <img style={{width:'90%', maxWidth:'300px'}} src="/images/articles/6/Smartbeam2.gif" alt="AnimatedIllustration"></img>
          <p>{t("article6.part3.content.3" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article6.part4.title" as any)}</h3>
          <p>{t("article6.part4.content.0" as any)}</p>
        </div>
      </div>
    </div>
  );
}

function Article7() {
  const { classes } = TestStyle();
  const { t } = useTranslation("articles");

  return (
    <div>
      <Intro type="article7" size={2} />
      <div className={classes.content}>
        <div className={classes.contentPart}>
          <h3>{t("article7.part1.title" as any)}</h3>
          <p>{t("article7.part1.content.0" as any)}</p>
          <p>
            <Trans t={t} i18nKey={"article7.part1.content.1" as any}>
              PreLink
              <a href="https://www.briefbuilder.com" target="_blank" rel="noreferrer">
                In Link
              </a>
              Post Link PreB
              <b>In B</b>
              Post B
            </Trans>
          </p>
          <img src="/images/articles/7/BB_requirements.png" alt="BBrequirements"></img>
          <p>
            <Trans t={t} i18nKey={"article7.part1.content.2" as any}>
              PreB
              <b>In B</b>
            </Trans>
          </p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article7.part2.title" as any)}</h3>
          <p>{t("article7.part2.content.0" as any)}</p>
          <p>
            <Trans t={t} i18nKey={"article7.part2.content.1" as any}>
              PreLink
              <a href="https://kabandy.com" target="_blank" rel="noreferrer">
                In Link
              </a>
              Post
              <Link to="/article_page_5">
                In Link
              </Link>
              Post Link
            </Trans>
          </p>
          <p>{t("article7.part2.content.2" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article7.part3.title" as any)}</h3>
          <p>
            <Trans t={t} i18nKey={"article7.part3.content.0" as any}>
              PreItalic <i>In Italic</i> PostItalic PreItalic <i>In Italic</i>
              PostItalic
            </Trans>
          </p>
          <p>
            <Trans t={t} i18nKey={"article7.part3.content.1" as any}>
              PreB <b>In B</b> PostB
            </Trans>
          </p>
          <img src="/images/articles/7/workflow.png" alt="Workflow"></img>
          <p>
            <Trans t={t} i18nKey={"article7.part3.content.2" as any}>
              PreB<b>In B</b>Post B <b>In B</b>Post B
            </Trans>
          </p>
          <img src="/images/articles/7/Board_generated.gif" alt="Animated Illustration"></img>
          <p>
            <Trans t={t} i18nKey={"article7.part3.content.3" as any}>
              PreB <b>In B</b> PostB
            </Trans>
          </p>

          <img src="/images/articles/7/Board_duplication.gif" alt="AnimatedIllustration"></img>
          <p>{t("article7.part3.content.4" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article7.part4.title" as any)}</h3>
          <p>{t("article7.part4.content.0" as any)}</p>
          <p>{t("article7.part4.content.1" as any)}</p>
          <p>
            <Trans t={t} i18nKey={"article7.part4.content.2" as any}>
              PreLink
              <a href="mailto:hello@kabandy.com">In Link</a>
              Post Link PreLink
              <a href="mailto:info@briefbuilder.com">In Link</a>
              Post Link
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
}

function Article8() {
  const { classes } = TestStyle();
  const { t } = useTranslation("articles");

  return (
    <div>
      <Intro type="article8" size={2} />
      <div className={classes.content}>
        <div className={classes.contentPart}>
          <h3>{t("article8.part1.title" as any)}</h3>
          <p>{t("article8.part1.content.0" as any)}</p>
          <p>{t("article8.part1.content.1" as any)}</p>
          <p>{t("article8.part1.content.2" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article8.part2.title" as any)}</h3>
          <p>{t("article8.part2.content.0" as any)}</p>
          <p>{t("article8.part2.content.1" as any)}</p>
          <ol>
            <li>
              <Trans t={t} i18nKey={"article8.part2.content.2" as any}>
                PreB <b>In B</b> PostB
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey={"article8.part2.content.3" as any}>
                PreB <b>In B</b> PostB
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey={"article8.part2.content.4" as any}>
                PreB <b>In B</b> PostB
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey={"article8.part2.content.5" as any}>
                PreB <b>In B</b> PostB
              </Trans>
            </li>
          </ol>
          <figure>
            <img src="/images/articles/8/Image2.png" alt="missing"></img>
            <figcaption>{t("article8.part2.content.6" as any)}</figcaption>
          </figure>
        </div>

        <div className={classes.contentPart}>
          <h3>{t("article8.part3.title" as any)}</h3>
          <p>{t("article8.part3.content.0" as any)}</p>
          <figure>
            <img src="images/articles/8/Image3.png" alt="missing"></img>
            <figcaption>{t("article8.part3.content.1" as any)}</figcaption>
          </figure>
          <p>{t("article8.part3.content.2" as any)}</p>
          <figure>
            <img src="/images/articles/8/Image4.png" alt="missing"></img>
            <figcaption>{t("article8.part3.content.3" as any)}</figcaption>
          </figure>
        </div>

        <div className={classes.contentPart}>
          <h3>{t("article8.part4.title" as any)}</h3>
          <p>{t("article8.part4.content.0" as any)}</p>
          <ol>
            <li>
              <Trans t={t} i18nKey={"article8.part4.content.1" as any}>
                PreB <b>In B</b> PostB
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey={"article8.part4.content.2" as any}>
                PreB <b>In B</b> PostB
              </Trans>
            </li>
          </ol>
          <p>{t("article8.part4.content.3" as any)}</p>
          <p>{t("article8.part4.content.4" as any)}</p>
          <p>{t("article8.part4.content.5" as any)}</p>
        </div>
      </div>
    </div>
  );
}

function Article9() {
  const { classes } = TestStyle();
  const { t } = useTranslation("articles");

  return (
    <div>
      <Intro type="article9" size={2} />
      <div className={classes.content}>
        <div className={classes.contentPart}>
          <h3>{t("article9.part1.title" as any)}</h3>
          <p>{t("article9.part1.content.0" as any)}</p>
          <p>
            <Trans t={t} i18nKey={"article9.part1.content.1" as any}>
              Prei <i>In i</i> Posti
            </Trans>
          </p>
          <p>{t("article9.part1.content.2" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article9.part2.title" as any)}</h3>
          <p>{t("article9.part2.content.0" as any)}</p>
          <p>{t("article9.part2.content.1" as any)}</p>
          <ul>
            <li>{t("article9.part2.content.2" as any)}</li>
            <li>{t("article9.part2.content.3" as any)}</li>
            <li>{t("article9.part2.content.4" as any)}</li>
          </ul>
          <p>{t("article9.part2.content.5" as any)}</p>
          <img style={{height:'300px', width:'40%'}} src="/images/articles/9/Kabandy-BIM-HQ.png" alt="KabandyBIMHQ"></img>
        </div>

        <div className={classes.contentPart}>
          <h3>{t("article9.part3.title" as any)}</h3>
          <p>
            <Trans t={t} i18nKey={"article9.part3.content.0" as any}>
              PreB <b>In B</b> PostB
            </Trans>
          </p>
          <p>
            <Trans t={t} i18nKey={"article9.part3.content.1" as any}>
              PreB <b>In B</b> PostB
            </Trans>
          </p>
          <p>{t("article9.part3.content.2" as any)}</p>
          <ul>
            <li>{t("article9.part3.content.3" as any)}</li>
            <li>{t("article9.part3.content.4" as any)}</li>
            <li>
              <Trans t={t} i18nKey={"article9.part3.content.5" as any}>
                PreLink
                <a href="https://www.adeb-vba.be/" target="_blank" rel="noreferrer">
                  InLink
                </a>
                PostLink
              </Trans>
            </li>
          </ul>

          <p>
            <Trans t={t} i18nKey={"article9.part3.content.6" as any}>
              PreB <b>In B</b> PostB
            </Trans>
          </p>
          <p>
            <Trans t={t} i18nKey={"article9.part3.content.7" as any}>
              PreB <b>In B</b> PostB
            </Trans>
          </p>
          <figure>
            <img style={{height:'auto'}} src="/images/articles/9/Photo-Management-Kabandy.JPG" alt="ManagementTeam"></img>
            <figcaption>{t("article9.part3.content.8" as any)}</figcaption>
          </figure>
        </div>

        <div className={classes.contentPart}>
          <h3>{t("article9.part4.title" as any)}</h3>
          <p>
            <a href="https://louisdewaele.be/" target="_blank" rel="noreferrer">Entreprises Louis de Waele</a>,{" "} 
            <a href="https://www.citblaton.be/fr" target="_blank" rel="noreferrer">CIT Blaton</a>,{" "} 
            <a href="https://bpcgroup.be/" target="_blank" rel="noreferrer">BPC groupe</a>,{" "} 
            <a href="https://www.besix.com/" target="_blank" rel="noreferrer">Besix</a>,{" "} 
            <a href="https://www.valens.eu/" target="_blank" rel="noreferrer">Valens</a>,{" "} 
            <a href="https://www.inadvance.be/" target="_blank" rel="noreferrer">In Advance</a>,{" "} 
            <a href="https://www.ores.be/" target="_blank" rel="noreferrer">ORES</a>,{" "} 
            <a href="https://www.rtbf.be/" target="_blank" rel="noreferrer">RTBF</a>,{" "} 
            <a href="https://www.atosborne.be/" target="_blank" rel="noreferrer">AT Osborne</a>,{" "} 
            <a href="https://www.grandest.fr/" target="_blank" rel="noreferrer">Région Grand-Est en France</a>
          </p>
        </div>
      </div>
    </div>
  );
}

function Article10() {
  const { classes } = TestStyle();
  const { t } = useTranslation("articles");

  return (
    <div>
      <Intro type="article10" size={2} />
      <div className={classes.content}>
        <div className={classes.contentPart}>
          <h3>{t("article10.part1.title" as any)}</h3>
          <p>{t("article10.part1.content.0" as any)}</p>
          <p>{t("article10.part1.content.1" as any)}</p>
          <p>{t("article10.part1.content.2" as any)}</p>
        </div>
        <div className={classes.contentPart}>
          <h3>{t("article10.part2.title" as any)}</h3>
          <p>{t("article10.part2.content.0" as any)}</p>
          <img src="/images/articles/10/image1.gif" alt="AnimatedIllustration"></img>
          <p>{t("article10.part2.content.1" as any)}</p>
          <ul>
            <li>{t("article10.part2.content.2" as any)}</li>
            <li>{t("article10.part2.content.3" as any)}</li>
            <li>{t("article10.part2.content.4" as any)}</li>
            <p>{t("article10.part2.content.5" as any)}</p>
          </ul>
        </div>

        <div className={classes.contentPart}>
          <h3>{t("article10.part3.title" as any)}</h3>
          <p>{t("article10.part3.content.0" as any)}</p>
        </div>
      </div>
    </div>
  );
}

function Article11() {
  const { classes } = TestStyle();
  const { t } = useTranslation("articles");

  return (
    <div>
      <Intro type="article11" size={2} />
      <div className={classes.content}>
        <div className={classes.contentPart}>
          <p><Trans t={t} i18nKey={"article11.part1.content.0" as any}>
            Prelink
            <a href="https://www.kabandy.com" target="_blank" rel="noreferrer">
              InLink
            </a>
            <a href="https://www.flexbim5d.com" target="_blank" rel="noreferrer">
              Inlink2
            </a>
            PostLink2
          </Trans></p>
          <p>{t("article11.part1.content.1" as any)}</p>
          <p><Trans t={t} i18nKey={"article11.part1.content.2" as any}>
            Prelink
            <a href="https://www.flexbim5d.com" target="_blank" rel="noreferrer">
              Inlink2
            </a>
            PostLink2
          </Trans></p>
          <p>{t("article11.part1.content.3" as any)}</p>
          <div style={{position:"relative", paddingBottom:"56.25%"}}>
            <iframe style={{width:"100%", height:"100%", position:"absolute", left:0, top:0}} src="https://www.youtube-nocookie.com/embed/10kdpiXVeKo?si=HXtLhDNHHyZBjEXk" title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

function Article12() {
  const { classes } = TestStyle();
  const { t } = useTranslation("articles");

  return (
    <div>
      <Intro type="article12" size={2} />
      <div className={classes.content}>
        <div className={classes.contentPart}>
          <p>{t("article12.part1.content.0" as any)}</p>
          <p>{t("article12.part1.content.1" as any)}</p>
          <div style={{position:"relative", paddingBottom:"56.25%"}}>
            <iframe style={{width:"100%", height:"100%", position:"absolute", left:0, top:0}} src="https://quadram.online/wp-content/uploads/2024/02/Video-Presentation-Module-BIM-by-KBDy.mp4" title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
          </div>
          <p><Trans t={t} i18nKey={"article12.part1.content.2" as any}>
            Prelink
            <a href="https://www.quadram.online/bim" target="_blank" rel="noreferrer">
              Inlink2
            </a>
            PostLink2
          </Trans></p>
        </div>
      </div>
    </div>
  );
}

export {
  Article1,
  Article2,
  Article3,
  Article4,
  Article5,
  Article6,
  Article7,
  Article8,
  Article9,
  Article10,
  Article11,
  Article12
};
