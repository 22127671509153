import { useLocation } from "react-router-dom";
import RessourcesCard from "../../Components/RessourcesCard/RessourcesCard";
import Contact from "../../Components/Contact/Contact";
import Navbar from "../../Components/Navbar/Navbar";
import { makeStyles } from "tss-react/mui";
import { ScrollToTop } from "../../Utilities/HelpfulFunctions";

const ids: number[][] = [
  [2, 3, 5, 6, 7, 8, 10],
  [1, 4, 9, 11, 12],
];

const TestStyle = makeStyles({ name: "RessourcesStyle" })((theme) => ({
  ressourcesCardContainer: {
    paddingTop: "135px",
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    justifyContent: "flex-start",
    width: "90%",
    margin: "0 auto 100px",
    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      gap:'80px'
    },
  },
}));

function Ressources() {
  const { classes } = TestStyle();
  let ressourcesType = 0;
  const location = useLocation();

  if (location.state !== null && location.state.type !== ressourcesType) {
    ressourcesType = location.state.type;
  }

  function renderResourcesCards() {
    let arrayOfCards: JSX.Element[] = [];
    for (let i = 0; i < ids[ressourcesType].length; i++) {
      arrayOfCards.push(
        <RessourcesCard
          key={ids[ressourcesType][i]}
          id={ids[ressourcesType][i]}
          type="articles"
        />
      );
    }
    return arrayOfCards.reverse();
  }

  return (
    <div>
      <ScrollToTop check={location.state != null ? location.state.type : null}/>
      <Navbar />
      <div className={classes.ressourcesCardContainer}>
        {renderResourcesCards()}
      </div>
      <Contact />
    </div>
  );
}

export default Ressources;
