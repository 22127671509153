import { keyframes } from "tss-react";
import { makeStyles } from "tss-react/mui";
import menusData from "./DropdownMenuData";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";

interface Props {
  name: string;
}

const TestStyle = makeStyles({ name: "DropdownMenuLangStyle" })((theme) => ({
  dropbtn: {
    color: "dimgrey",
    fontSize: theme.desktop.FSNB,
    padding: "0",
    border: "none",
    letterSpacing:"0.05em",
    backgroundColor: "Transparent",
    [`@media screen and (max-width:${theme.breakpoints.NAVBAR})`]: {
      fontSize: theme.mobile.FSNB,
    },
  },

  dropdownContent: {
    display:"flex",
    flexDirection:'column',
    gap:'0px',
    padding:'0px 0px',
    maxHeight:'0px',
    opacity:'0%',
    overflow:'hidden',
    position: "absolute",
    right: "0%",
    borderRadius: "15px",
    backgroundColor: "rgb(255, 255, 255)",
    zIndex: "20",
    transitionProperty: "max-height, opacity, gap, padding",
    transitionDuration: ".15s",
    transitionTimingFunction: "ease-in",
    boxShadow:" 0px 3px 9px rgb(0 0 0 / 15%)",

    a: {
      borderRadius: "15px",
      color: "black",
      padding: "0px 16px",
      textDecoration: "none",
      display: "block",
      minWidth: "150px",
      whiteSpace: "nowrap",
      textAlign:'left',

      "&:hover": {
        color: theme.colors.primary.PRIMARY,
      },
    },
  },

  dropdown: {
    position: "relative",
    display: "inline-block",
    width: "100%",

    "&:hover": {
      div: {
        maxHeight:'300px',
        opacity:'100%',
        gap:'24px',
        padding:'12px 0px',
      },
    },

    [`@media screen and (max-width:${theme.breakpoints.NAVBAR}) and (min-width:${theme.breakpoints.S})`]:{
      div : {
        right: "120px",
        top: "-100%",
      }
    },

    [`@media screen and (max-width:${theme.breakpoints.S})`]: {
      div :{
        right:"unset",
        left:'50%',
        transform: "translateX(-50%)"
      }
    },
  },

  dropdownLang: {
    position: "relative",
    display: "inline-block",
    width: "100%",

    "&:hover": {
      div: {
        maxHeight:'300px',
        opacity:'100%',
        gap:'24px',
        padding:'12px 0px',
      },
    },

    [`@media screen and (max-width:${theme.breakpoints.NAVBAR}) and (min-width:${theme.breakpoints.S})`]:{
        div : {
          right: "120px",
          bottom:'0%'
        }
      },

    [`@media screen and (max-width:${theme.breakpoints.S})`]: {
      div :{
        right:"unset",
        left:'50%',
        bottom:'100%',
        transform: "translateX(-50%)"
      }
    },
  },
}));

function DropdownMenu(props: Props) {
  const { classes } = TestStyle();
  const navigation = useNavigate();
  const { t } = useTranslation("navbar");

  function renderMenu(name: string) {
    let MenuItems: JSX.Element[] = [];
    for (let i = 0; i < t(`${name}List` as any).length; i++) {
      MenuItems.push(
        <a key={i} onClick={() => handleClickItem(i)}>
          {t(`${name}List.${i}` as any)}
        </a>
      );
    }
    return MenuItems;
  }

  const handleClickItem = (i: number) => {
    navigation(menusData[props.name].links[i], { state: { type: i } });
  };

  return (
    <div className={classes.dropdown}>
      <button className={classes.dropbtn} aria-label="DropdownButton">{t(props.name as any)}</button>
      <div className={classes.dropdownContent}>{renderMenu(props.name)}</div>
    </div>
  );
}

function DropdownMenuLang(props: Props) {
  const { classes } = TestStyle();
  const { t, i18n } = useTranslation();

  function renderMenu(name: string) {
    let MenuItems: JSX.Element[] = [];
    for (const [key, value] of Object.entries(menusData[name])) {
      MenuItems.push(
        <a key={key} onClick={() => i18n.changeLanguage(key)}>
          {value as string}
        </a>
      );
    }
    return MenuItems;
  }

  return (
    <div className={classes.dropdownLang}>
      <button className={classes.dropbtn} aria-label="DropdownLangButton">
        <LanguageIcon />
      </button>
      <div className={classes.dropdownContent}>{renderMenu(props.name)}</div>
    </div>
  );
}

export { DropdownMenu, DropdownMenuLang };
