import { useParams } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Contact from "../../Components/Contact/Contact";
import {
  Article1,
  Article2,
  Article3,
  Article4,
  Article5,
  Article6,
  Article7,
  Article8,
  Article9,
  Article10,
  Article11,
  Article12
} from "./Articles";

function ArticleSwitch() {
  const { article_id } = useParams();

  function renderArticle() {
    switch (parseInt(article_id as string)) {
      case 1:
        return <Article1 />;
      case 2:
        return <Article2 />;
      case 3:
        return <Article3 />;
      case 4:
        return <Article4 />;
      case 5:
        return <Article5 />;
      case 6:
        return <Article6 />;
      case 7:
        return <Article7 />;
      case 8:
        return <Article8 />;
      case 9:
        return <Article9 />;
      case 10:
        return <Article10 />;
      case 11:
        return <Article11/>;
      case 12:
        return <Article12/>;
      default:
        return <Article1 />;
    }
  }

  return (
    <div>
      <Navbar />
      <div>{renderArticle()}</div>
      <Contact />
    </div>
  );
}

export default ArticleSwitch;
