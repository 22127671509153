import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import introData from "./IntroData";

interface Props {
  type: string;
  size: number;
  FSH1?:string;
  FSH2?:string;
  title?: string;
  subtitle?: string
}

const size: string[] = ["250px", "350px", "420px", "550px"];

const TestStyle = makeStyles({ name: "IntroStyle" })((theme, props: any) => ({
  intro: {
    height: size[props.size],
    paddingTop: theme.desktop.NAVBARHEIGHT,
    backgroundImage: `url(${introData[props.type]})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",

    h1: {
      width: "90%",
      fontSize: props.FSH1 ? props.FSH1 : theme.desktop.FSH1,
    },
    h2: {
      width: "90%",
      fontSize: props.FSH2 ? props.FSH2 : theme.desktop.FSH2,
    },
    "h1, h2": {
      color: "white",
      textShadow: "2px 2px #555",
      fontWeight: "400",
    },

    [`@media screen and (max-width:${theme.breakpoints.L})`]: {
      h1: {
        fontSize: theme.mobile.FSH1,
      },
      h2: {
        fontSize: theme.mobile.FSH2,
      },
    },
  },
}));

function Intro(props: Props) {
  const { classes } = TestStyle(props as any);
  const { t } = useTranslation("intro");

  const title = props.title != null ? props.title : t(`${[props.type]}.title` as any)
  const subtitle = props.subtitle != null ? props.subtitle : t(`${[props.type]}.subtitle` as any)

  return (
    <div>
      <div className={classes.intro}>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
    </div>
  );
}

export default Intro;
