import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

interface Props {
  number: number;
  page: string;
  pagenb: number;
  img: string;
}

const TestStyle = makeStyles({ name: "SideImgStyle" })((theme) => ({
  sideImgComponent: {
    display: "flex",
    flexDirection: "row",
    gap: "50px",
    justifyContent: "center",

    img: {
      width: "55%",
      maxWidth:"850px",
      flexShrink: 0,
      //maxHeight: "450px",
    },

    [`@media screen and (min-width:${theme.breakpoints.M})`]: {
      "&:nth-of-type(even)": {
        flexDirection: "row-reverse",
      },
    },

    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      gap: theme.spacing(2),
      img: {
        width: "100%",
      },
    },
  },

  sideImgText: {
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    justifyContent: "center",
    h3: {
      fontSize: theme.desktop.FSH3,
      marginBottom: theme.spacing(1),
      fontWeight:'700',
      [`@media screen and (max-width:${theme.breakpoints.M})`]: {
        fontSize: theme.mobile.FSH3,
      }
    },

    p: {
      color: theme.colors.primary.PTEXT,
    },
  },
}));

function SideImg(props: Props) {
  const { classes } = TestStyle();
  const { t } = useTranslation(["home", "product", "articles"]);

  const value =
    props.pagenb === 0 ? "sideImg" : "article" + props.pagenb + ".sideImg";

  return (
    <div className={classes.sideImgComponent}>
      <img src={props.img} alt="Illustrating" />
      <div className={classes.sideImgText}>
        <h3>
          {t(`${value}.${props.number}.title` as any, { ns: props.page })}
        </h3>
        <p>
          {t(`${value}.${props.number}.content` as any, { ns: props.page })}
        </p>
      </div>
    </div>
  );
}

export default SideImg;
