interface IPage {
  solutions: string[];
  name: string;
}

const dataCost: IPage = {
  name: "cost",
  solutions: [
    "/images/solutions/solutions-cost-img1.webp",
    "/images/solutions/solutions-cost-img2.webp",
    "/images/solutions/solutions-cost-img3.webp",
  ],
};

const dataProgress: IPage = {
  name: "progress",
  solutions: [
    "/images/solutions/solutions-progress-img1.webp",
    "/images/solutions/solutions-progress-img2.webp",
    "/images/solutions/solutions-progress-img3.webp",
  ],
};

const dataMaintenance: IPage = {
  name: "maintenance",
  solutions: [
    "/images/solutions/solutions-maintenance-img1.webp",
    "/images/solutions/solutions-maintenance-img2.webp",
    "/images/solutions/solutions-maintenance-img3.webp",
  ],
};
const dataQuality: IPage = {
  name: "quality",
  solutions: [
    "/images/solutions/solutions-quality-img1.webp",
    "/images/solutions/solutions-quality-img2.webp",
    "/images/solutions/solutions-quality-img3.webp",
    "/images/solutions/solutions-quality-img4.webp",
  ],
};

export { dataCost, dataProgress, dataMaintenance, dataQuality };
