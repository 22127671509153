import { makeStyles } from "tss-react/mui";
import Navbar from "../../Components/Navbar/Navbar";
import SideImgList from "../../Components/SideImgList/SideImgList";
import Contact from "../../Components/Contact/Contact";
import { useLocation } from "react-router-dom";
import {
  dataCost,
  dataProgress,
  dataMaintenance,
  dataQuality,
} from "./SolutionsData";
import Intro from "../../Components/Intro/Intro";
import { ScrollToTop } from "../../Utilities/HelpfulFunctions";

const TestStyle = makeStyles({ name: "SolutionsStyle" })((theme) => ({
  SideImgListContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems:"center",
    margin: "30px auto",
    gap: theme.desktop.SIDEIMGGAP,
    maxWidth:"1200px",

    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      alignItems: "center",
      gap: "60px",
      img: {
        width: "100%",
      },
    },
  },
}));

function Solutions() {
  const { classes } = TestStyle();
  const location = useLocation();
  let dataPage = dataCost;

  if (location.state != null) {
    switch (location.state.type) {
      case 1:
        dataPage = dataProgress;
        break;
      case 2:
        dataPage = dataMaintenance;
        break;
      case 3:
        dataPage = dataQuality;
        break;
      default:
        dataPage = dataCost;
    }
  }

  return (
    <div>
      <ScrollToTop check={location.state != null ? location.state.type : null}/>
      <Navbar />
      <Intro type={dataPage.name} size={3} />

      <div className={classes.SideImgListContainer}>
        {Object.values(dataPage.solutions).map((img, idx) => {
          return (
            <SideImgList
              key={dataPage.name + idx}
              img={img}
              name={dataPage.name}
              number={idx}
            />
          );
        })}
      </div>

      <Contact />
    </div>
  );
}

export default Solutions;
