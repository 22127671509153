import { makeStyles } from "tss-react/mui";
import Navbar from "../../Components/Navbar/Navbar";
import SideImg from "../../Components/SideImg/SideImg";
import Contact from "../../Components/Contact/Contact";
import Intro from "../../Components/Intro/Intro";

const productImgs: string[] = [
  "/images/product/product-1-resized.webp",
  "/images/product/product-2-resized.webp",
  "/images/product/product-3-resized.webp",
];

const TestStyle = makeStyles({ name: "ProductStyle" })((theme) => ({
  SideImgContainer: {
    margin: "70px 5%",
    display: "flex",
    flexDirection: "column",
    gap: "70px",
  },
}));

function Product() {
  const { classes } = TestStyle();
  const introSize = window.screen.width >= 768 ? 1 : 3

  return (
    <div>
      <Navbar />
      <Intro type="product" size={introSize} FSH1="40px" FSH2="20px"/>

      <div className={classes.SideImgContainer}>
        <SideImg number={0} page="product" pagenb={0} img={productImgs[0]} />
        <SideImg number={1} page="product" pagenb={0} img={productImgs[1]} />
        <SideImg number={2} page="product" pagenb={0} img={productImgs[2]} />
      </div>

      <Contact />
    </div>
  );
}

export default Product;
