import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { Link } from "react-router-dom";
import WebinarModal from "../WebinarModal/WebinarModal"

interface Props {
  id: number;
  type: string;
  openModal?: boolean;
}

const imgArticles: string[] = [
  "", //Because Article begin with article1 and not article0
  "/images/articles/1/article_overview.png",
  "/images/articles/2/article_overview.png",
  "/images/articles/3/article_overview.png",
  "/images/articles/4/article_overview.png",
  "/images/articles/5/article_overview.png",
  "/images/articles/6/article_overview.png",
  "/images/articles/7/article_overview.png",
  "/images/articles/8/article_overview.png",
  "/images/articles/9/article_overview.png",
  "/images/articles/10/article_overview.png",
  "/images/articles/11/article_overview.png",
  "/images/articles/12/Image_Quadram.jpg"
];

const imgWebinars: string[] = [
  "/images/webinars/0/webinar_overview.png"
]

const TestStyle = makeStyles({ name: "RessourcesCardStyle" })((theme) => ({
  ressourcesCard: {
    display: "flex",
    gap: "50px",
    width: "100%",

    img: {
      width: "30%",
      minWidth:'250px',
      maxHeight:"300px",
      objectFit: "fill",
    },
    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      flexDirection: "column",
      alignItems:"center",
      gap:'30px',
      img: {
        width: "80%",
        maxWidth:'350px',
        objectFit: "fill",
      },
    },
  },
  ressourcesCardText: {
    display: "flex",
    flexDirection: "column",
    paddingTop:'10px',
    gap: "15px",
    width: "60%",

    h5: { 
      fontSize:'20px',
      fontWeight:'600'
    },
    p : { fontSize:"15px"},

    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      width: "100%",
    },

    a: {
      textDecoration:'none',
      color:theme.colors.primary.PTEXT
    },

    button: {
      backgroundColor:"transparent",
      border: "none",
      width: "fit-content",
      "&:hover": {
        cursor:"pointer"
      },
      color:theme.colors.primary.PTEXT,
      fontSize: 16
    }
  },
}));

function RessourcesCard(props: Props) {
  const { classes } = TestStyle();
  const { t } = useTranslation("resources");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(props.openModal ? props.openModal : false);

  if (props.type === "articles") {
    return (
      <div className={classes.ressourcesCard}>
        <img src={imgArticles[props.id]} alt={`article_nb${props.id}`} />
        <div className={classes.ressourcesCardText}>
          <h5>{t(`articles.${props.id}.title` as any)}</h5>
          <p>{t(`articles.${props.id}.content` as any)}</p>
          <hr></hr>
          <Link to={`/article_page_${props.id.toString()}`}>{t(`articles.${props.id}.link` as any)}</Link>
        </div>
      </div>
    );
  } else if (props.type === "webinars") {
    return (
      <div className={classes.ressourcesCard}>
        <img src={imgWebinars[props.id]} alt={`webinars_nb${props.id}`} />
        <div className={classes.ressourcesCardText}>
          <h5>{t(`webinars.${props.id}.title` as any)}</h5>
          <p>{t(`webinars.${props.id}.content` as any)}</p>
          <hr></hr>
          <button onClick={() => setIsModalOpen(true)}>{t(`webinars.${props.id}.link` as any)}</button>
          {isModalOpen && <WebinarModal id={props.id} onClose={() => setIsModalOpen(false)} title={t(`webinars.${props.id}.title` as any, {lng: "en"})}/>}
        </div>
      </div>
    );
  } else {
    return (<div>Error : This page should not be visible</div>)
  }
  
}

export default RessourcesCard;
