const introData: { [key: string]: string } = {
  product: "/images/product/background-product-resized.webp",
  about: "/images/about/about_company.jpg",
  jobs: "/images/about/about_jobs.jpg",
  jobDevFS: "/images/about/about_job_fullstack.jpg",
  internships: "/images/about/about_internships.jpg",
  quality: "/images/solutions/solutions_quality.webp",
  progress: "/images/solutions/solutions_progress.webp",
  maintenance: "/images/solutions/solutions_maintenance.webp",
  cost: "/images/solutions/solutions_cost.webp",
  article1: "/images/articles/1/article_background.jpg",
  article2: "/images/articles/2/article_background.jpg",
  article3: "/images/articles/3/article_background.jpg",
  article4: "/images/articles/4/article_background.jpg",
  article5: "/images/articles/5/article_background.jpg",
  article6: "/images/articles/6/article_background.jpg",
  article7: "/images/articles/7/article_background.jpg",
  article8: "/images/articles/8/article_background.jpg",
  article9: "/images/articles/9/article_background.jpg",
  article10: "/images/articles/10/article_overview.png",
  article11: "/images/articles/11/article_background.jpg",
  article12: "/images/articles/11/article_background.jpg" //NOCOMMIT
};

export default introData;
