import { makeStyles } from "tss-react/mui";
import Navbar from "../../Components/Navbar/Navbar";
import Contact from "../../Components/Contact/Contact";
import Intro from "../../Components/Intro/Intro";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TestStyle = makeStyles({ name: "InternshipsStyle" })((theme) => ({
  textInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    width: "min(80%, 900px)",
    margin: "80px auto 0",
    color:theme.colors.primary.PTEXT,
    a :{
      textDecoration:'none'
    }
  },

  jobsContent: {
    display: "flex",
    justifyContent: "center",
    gap: "50px",
    width: "80%",
    margin: "80px auto 30px auto",
  },

  notHiring: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    margin: "0 10% 60px 10%",
    span: {
      color: "red",
    },
  },
}));

function Internships() {
  const { classes } = TestStyle();
  const { t } = useTranslation("internships");
  return (
    <div>
      <Navbar />
      <Intro type="internships" size={0} />

      <div className={classes.textInfo}>
        <p>{t("content.0" as any)}</p>

        <p>
          <Trans t={t} i18nKey={"content.1" as any}>
            Prelink<Link to="/jobs">Link</Link>PostLink
          </Trans>
        </p>
      </div>
      <div className={classes.jobsContent}>
      </div>

      <div className={classes.notHiring}>
        <h2>{t("notHiring" as any)}</h2>
      </div>

      <Contact />
    </div>
  );
}

export default Internships;
