import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "tss-react/mui";
import mainStyle from "../../Utilities/MainStyle";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4"

const INPUTHEIGHT: string = "30px";
const ITEMWIDTH: string = "100%";
const MODALFONTSIZE: string = "15px";
const MOBILEFONTSIZE: string = "12px";
const MOBILEINPUTHEIGHT: string = "25px";
const REQUESTURL:string = 'https://app.kabandy.com/freetrial';

interface Props {
  type: string;
  onClose:Function;
}


const TestStyle = makeStyles({ name: "TrialModalStyle" })((theme) => ({
  trialModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "600px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: "24",
    backgroundColor: "white",
    borderRadius: "10px",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),

    form: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      flexGrow: "1",
    },
  },

  trialModalText: {
    width: "100%",
    p: {
      margin: "20px 0",
      fontSize: MODALFONTSIZE,
      [`@media screen and (max-width:${mainStyle.BREAKPOINTS.S})`]: {
        fontSize: MOBILEFONTSIZE,
      },
    },
  },
  

  trialModalInputs: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),

    input: {
      display: "block",
      border:"1px solid black",
      borderRadius: "5px",
      paddingLeft: theme.spacing(0.5),
      width: ITEMWIDTH,
      height: INPUTHEIGHT,
      fontSize: MODALFONTSIZE,
      [`@media screen and (max-width:${mainStyle.BREAKPOINTS.S})`]: {
        fontSize: MOBILEFONTSIZE,
        height: MOBILEINPUTHEIGHT,
      },
    },
    select: {
      width: ITEMWIDTH,
      height: INPUTHEIGHT,
      fontSize: MODALFONTSIZE,
      border:"1px solid black",
      [`@media screen and (max-width:${mainStyle.BREAKPOINTS.S})`]: {
        height: MOBILEINPUTHEIGHT,
        fontSize: MOBILEFONTSIZE,
      },
    },

    textarea: {
      width: ITEMWIDTH,
      fontSize: MOBILEFONTSIZE,
      border:"1px solid black"
    },
  },

  labelItem: {
    fontSize: MODALFONTSIZE,
    [`@media screen and (max-width:${mainStyle.BREAKPOINTS.S})`]: {
      fontSize: MOBILEFONTSIZE,
    },
  },

  trialModalButtons: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(1),
    height: "100%",

    button: {
      height: "40px",
      fontSize: MODALFONTSIZE,
      padding: "5px 10px",
      border: "none",
      borderRadius: "3px",
      "&:nth-of-type(1)": {
        backgroundColor: mainStyle.COLORS.PRIMARY,
        color: "white",
      },
      [`@media screen and (max-width:${mainStyle.BREAKPOINTS.S})`]: {
        fontSize: MOBILEFONTSIZE,
      },
    },
  },
}));

function TrialModal(props: Props) {
  const { classes } = TestStyle();
  const { t } = useTranslation("modal");
  const [mailError, setMailError] = useState(false);

  useEffect(() =>{
    ReactGA.send({
      hitType: 'pageview', 
      title: 'FreeTrialModal'
    })
  }, [])

  async function postTrial(url:string, data: {[key:string] :any}) {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
      },
      body: JSON.stringify(data)
    });
    return response;
  }

  function emailTestFormat(email:string) {
    return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
  }

  async function handleFormSubmit(event: any) {
    event.preventDefault();
    if (!emailTestFormat(event.target.email.value)){
      setMailError(true);
    }
    else {
      const data = new FormData(event.target);
      const values = Object.fromEntries(data.entries());
      // values.reporter = window.location.href.split("kabandy.com/freetrial/")[1];
      await postTrial(REQUESTURL, values);
      event.target.reset();
      props.onClose();
    }    
  }

  return (
    <div>
      <Modal
        keepMounted
        open={true}
        onClose={() => props.onClose()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className={classes.trialModal}>
          <div className={classes.trialModalText}>
            <h2>{t("freeTrial.title" as any)}</h2>
            <p>{t("freeTrial.inputs.message" as any)}</p>
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className={classes.trialModalInputs}>
              <input required name="firstName" placeholder={t("freeTrial.inputs.firstName" as any)} />
              <input required name="lastName" placeholder={t("freeTrial.inputs.lastName" as any)} />
              <input onClick={() => setMailError(false)} style={{border: mailError ? "2px solid red" : "1px solid black"}} required name="email" placeholder={t("freeTrial.inputs.email" as any)} />
              <input required name="company" placeholder={t("freeTrial.inputs.company" as any)} />
              <input required name="phone" placeholder={t("freeTrial.inputs.phone" as any)} />
              <input required name="jobTitle" placeholder={t("freeTrial.inputs.jobTitle" as any)} />
              <select required name="language" id="langSelect" defaultValue={""}>
                <option value="" disabled hidden>
                  {t("freeTrial.inputs.language" as any)}
                </option>
                <option value="fr">Français</option>
                <option value="en">English</option>
                <option value="nl">Nederlands</option>
              </select>
              <label htmlFor="textGoal" className={classes.labelItem}>
                {t("freeTrial.inputs.goal" as any)}
              </label>
              <textarea name="motivation" rows={3} id="textGoal"></textarea>
            </div>
            <div className={classes.trialModalButtons}>
              <button type="submit" aria-label="FormSubmitButton">{t("freeTrial.inputs.send" as any)}</button>
              <button type="button" onClick={() => props.onClose()} aria-label="FormCancelButton">
                {t("freeTrial.inputs.close" as any)}
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export default TrialModal;
