import { useLocation, useParams } from "react-router-dom";
import RessourcesCard from "../../Components/RessourcesCard/RessourcesCard";
import Contact from "../../Components/Contact/Contact";
import Navbar from "../../Components/Navbar/Navbar";
import { makeStyles } from "tss-react/mui";
import { ScrollToTop } from "../../Utilities/HelpfulFunctions";

const ids: number[] = [
  0
];

const TestStyle = makeStyles({ name: "WebinarsStyle" })((theme) => ({
  ressourcesCardContainer: {
    paddingTop: "135px",
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    justifyContent: "flex-start",
    width: "90%",
    margin: "0 auto 100px",
    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      gap:'80px'
    },
  },
}));

function Webinars() {
  const { classes } = TestStyle();
  const location = useLocation();
  const { webinarId } = useParams();

  function renderResourcesCards() {
    let arrayOfCards: JSX.Element[] = [];
    for (let i = 0; i < ids.length; i++) {
      const propsObject: any = {
        key: ids[i],
        id: ids[i],
        type: "webinars",
        openModal: false
      }

      if (parseInt(webinarId as string) === ids[i]) propsObject.openModal = true
      arrayOfCards.push(
        <RessourcesCard
          // key={ids[i]}
          // id={ids[i]}
          // type="webinars"
          // openModalId={parseInt(webinarId as string)}
          {...propsObject}
        />
      );
    }
    return arrayOfCards.reverse();
  }

  return (
    <div>
      <ScrollToTop check={location.state != null ? location.state.type : null}/>
      <Navbar />
      <div className={classes.ressourcesCardContainer}>
        {renderResourcesCards()}
      </div>
      <Contact />
    </div>
  );
}

export default Webinars;