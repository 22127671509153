const menusData: { [key: string]: any } = {
  solutions: {
    title: "Solutions",
    links: ["/solutions"],
  },
  about: {
    title: "A Propos",
    links: ["/about", "/jobs", "/internships"],
  },
  resources: {
    title: "Ressources",
    links: ["/ressources", "/ressources", "/webinars"],
  },
  langs: {
    en: "English",
    fr: "Français",
    nl: "Nederlands",
  },
};

export default menusData;
