import { makeStyles } from "tss-react/mui";
import TrialModal from "../TrialModal/TrialModal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useState } from "react";

const TestStyle = makeStyles({ name: "ContactStyle" })((theme) => ({
  contactComponent: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  testField: {
    backgroundColor: theme.colors.primary.PRIMARY,
    width: "100%",
    height: "200px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "30px",
    alignItems: "center",

    h3: {
      fontSize: theme.desktop.FSH3,
      color: "white",
    },

    button: {
      backgroundColor: "white",
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      color: theme.colors.primary.PRIMARY,
      lineHeight: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "20px",
      border:"none",
      fontSize:"17px"
    },
  },

  contacts: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    padding: "25px",
    gap: "40px 100px",
    backgroundColor: theme.colors.primary.BGCONTACT,
  },

  contactField: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    img: {
      width: "40px",
    },
    "a, button": {
      textDecoration: "none",
      color: theme.colors.secondary.SECONDARY,
      fontWeight: "200",
      fontSize: "12px",
      letterSpacing:'0.08em'
    },
    button : {
      textAlign:"left",
      cursor: "pointer",
      border:"none",
      backgroundColor:"transparent",
    },
      
    h5: {
      fontSize: theme.desktop.FSH5,
      color: "white",
      fontWeight: "300",
    },
  },
  botFooter: {
    backgroundColor: theme.colors.primary.BGCONTACT,
    width: "100%",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.colors.secondary.SECONDARY,
    h5: {
      fontWeight: "300",
      textTransform: "uppercase",
      letterSpacing: "0.1em",
      textAlign: "center",
    },
  },
}));

function Contact() {
  const { classes } = TestStyle();
  const { t } = useTranslation("contact");
  const [isModalOpen, setIsModalOpen] = useState(false);


  return (
    <div className={classes.contactComponent}>
      <div className={classes.testField}>
        <h3>{t("title")}</h3>
        <button onClick={() => setIsModalOpen(!isModalOpen)} aria-label="ContactTrialModal">{t("buttonContent" as any)}</button>
        {isModalOpen && <TrialModal onClose={() => setIsModalOpen(false)} type="home" />}
      </div>
      <div className={classes.contacts}>
        <div className={classes.contactField}>
          <h5>{t("contacts.title")}</h5>
          <img src="/images/logo/logo_without_text.webp" alt="Kabandy_Logo" />
          <a href="https://goo.gl/maps/PDGNAMqKRB3Vk8bZ8">
            Rue des Pères Blancs, 4, 1040 Etterbeek, Belgique
          </a>
          <a href="https://goo.gl/maps/w1tFTSxrpqTT6ERj9">
            Quai Paul Verlaine, 2 (boîte 2), 6000 Charleroi, Belgique
          </a>
          <a href="tel:+3227376753">+32 (0)2 737 67 53</a>
        </div>

        <div className={classes.contactField}>
          <h5>{t("links.title")}</h5>
          <Link to="/about">{t("links.contentList.0" as any)}</Link>
          <Link to="/product">{t("links.contentList.1" as any)}</Link>
          <Link to="/solutions">{t("links.contentList.2" as any)}</Link>
          <Link to="/portfolio">{t("links.contentList.3" as any)}</Link>
          <Link to="/ressources">{t("links.contentList.4" as any)}</Link>
          <a href="https://youtu.be/sBrF-bV6RNg">Video</a>   
          <a href="https://app.kabandy.com">{t("links.contentList.5" as any)}</a>   
          <button onClick={() => setIsModalOpen(!isModalOpen)} aria-label="ContactTrialModal">{t("links.contentList.6" as any)}</button>
        </div>

        <div className={classes.contactField}>
          <h5>{t("conventions.title")}</h5>
          <a href="/assets/pdf/Kabandy_Terms_of_Services.pdf">{t("conventions.contentList.0" as any)}</a>   
          <Link to="/privacy">{t("conventions.contentList.1" as any)}</Link>
        </div>
      </div>
      <div className={classes.botFooter}>
        <h5>{t("footer")}</h5>
      </div>
    </div>
  );
}

export default Contact;
