import { useEffect } from "react";
import { useLocation } from "react-router-dom"

function validateURL(url:string) {
	const parsed = new URL(url)
	return ['https:', 'http:'].includes(parsed.protocol)
}

interface IScroll {
	check?:string
}

function ScrollToTop(props:IScroll) {
	const {pathname} = useLocation()
	const valueToCheck = props.check ? props.check : pathname

	useEffect(() => {
		window.scrollTo(0, 0);	
	}, [valueToCheck]);

	return <></>
}

export {validateURL, ScrollToTop}