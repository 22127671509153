import { makeStyles } from "tss-react/mui";
import Navbar from "../../Components/Navbar/Navbar";
import { IconCard, IconTable } from "../../Components/IconCard/IconCard";
import ClientsComponent from "../../Components/Clients/Clients";
import SideImg from "../../Components/SideImg/SideImg";
import ForAll from "../../Components/ForAll/ForAll";
import Contact from "../../Components/Contact/Contact";
import TrialModal from "../../Components/TrialModal/TrialModal";
import { useTranslation } from "react-i18next";
import { useState } from "react";

type IHomeProps = {
  freetrial: boolean;
}

Home.defaultProps = {
  freetrial: false
};

const TestStyle = makeStyles({ name: "HomeStyle" })((theme) => ({
  homePage: {
    display:"flex",
    flexDirection:"column",
    gap:theme.desktop.MAINCONTAINERGAP,
    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      gap : theme.mobile.MAINCONTAINERGAP,
    },
  },

  intro: {
    height: "100vh",
    width: "100%",
    paddingTop: theme.desktop.NAVBARHEIGHT,
    backgroundImage: "url(/images/background/background_home.webp)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: "30px",
  },

  introTitles: {
    width: "90%",
    h1: {
      fontSize: theme.desktop.FSH1,
    },
    h2: {
      fontSize: theme.desktop.FSH2,
    },
    "h1, h2": {
      color: "white",
      textShadow: "2px 2px #555",
      fontWeight:'400'
    },
    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      h1: {
        fontSize: theme.mobile.FSH1,
      },
      h2: {
        fontSize: theme.mobile.FSH2,
      },
    },
  },

  buttonDiv: {
    marginTop: "20px",
    display: "flex",
    gap: "80px",
    a: {
      padding: "15px 40px",
      borderRadius: "30px",
      border: "none",
      fontSize: theme.desktop.FSBIGBUTTONS,
      cursor: "pointer",
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "black",
    },
    button:{
      fontSize: theme.desktop.FSBIGBUTTONS,
      backgroundColor: theme.colors.primary.PRIMARY,
      cursor: "pointer",
      color: "white",
      border:"none",
      padding: "15px 40px",
      borderRadius: "30px",
    },
    [`@media screen and (max-width:${theme.breakpoints.S})`]: {
      gap: "40px",
      "a, button": {
        padding: "10px 20px",
        fontSize: theme.mobile.FSBIGBUTTONS,
        borderRadius: "20px",
      },
    },
  },
  iconContainer: {
    margin: "0 5%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    gap: "5%",
    rowGap: "20px",

    [`@media screen and (max-width:${theme.breakpoints.S})`]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  clients: {
    h3: {
      fontSize: theme.desktop.FSH3,
      width:'90%'
    },
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    alignItems: "center",
    textAlign: "center",
  },

  SideImgContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.desktop.SIDEIMGGAP,
    margin: "0 5%",
  },

  sideImg: {
    display: "flex",
    flexDirection: "row",
    gap: "100px",
    justifyContent: "center",
    alignItems: "center",

    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      flexDirection: "column",
      gap: "30px",
    },
  },

  sideImgText: {
    width: "50%",
    h3: {
      fontSize: theme.desktop.FSH3,
      marginBottom: "15px",
    },
    p: {
      color: theme.colors.primary.PTEXT,
    },

    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      width: "100%",
      h3: {
        fontSize: theme.mobile.FSH3,
      },
    },
  },
  forAllContainer:{
    margin:"0 5%"
  }
}));

function Home(props: IHomeProps) {
  const { classes } = TestStyle();
  const { t } = useTranslation("home");
  const [isModalOpen, setIsModalOpen] = useState(props.freetrial);

  return (
    <div className={classes.homePage}>
      <Navbar />
      <div className={classes.intro}>
        <div className={classes.introTitles}>
          <h1>{t("title")}</h1>
          <h2>{t("subtitle")}</h2>
        </div>
        <div className={classes.buttonDiv}>
          <a href="https://youtu.be/sBrF-bV6RNg">
            {t("introButtons.0" as any)}
          </a>
          <button onClick={() => setIsModalOpen(!isModalOpen)} aria-label="HomeTrialModal">{t("introButtons.1" as any)}</button>
          {isModalOpen && <TrialModal onClose={() => setIsModalOpen(false)} type="home" />}
        </div>
      </div>

      <div className={classes.iconContainer}>
        <IconCard number={0} />
        <IconCard number={1} />
        <IconCard number={2} />
      </div>

      <div className={classes.clients}>
        <h3>{t("clientsTitle")}</h3>
        <ClientsComponent />
      </div>

      <div className={classes.SideImgContainer}>
        <SideImg
          number={0}
          page="home"
          pagenb={0}
          img="/images/product/intro-product-resized.webp"
        />
        <div className={classes.sideImg}>
          <div className={classes.sideImgText}>
            <h3>{t("tableText.title" as any)}</h3>
            <p>{t("tableText.content" as any)}</p>
          </div>
          <IconTable />
        </div>
      </div>

      <div className={classes.forAllContainer}>
        <ForAll />
      </div>

      <Contact />
    </div>
  );
}

export default Home;
