import { makeStyles } from "tss-react/mui";
import Navbar from "../../Components/Navbar/Navbar";
import Intro from "../../Components/Intro/Intro";
import Contact from "../../Components/Contact/Contact";
import { Trans, useTranslation } from "react-i18next";

const TestStyle = makeStyles({ name: "AboutStyle" })((theme) => ({
  content: {
    margin: "0 auto",
    padding: "80px 0",
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "40px",

    "p, li" : {
      color:theme.colors.primary.PTEXT
    },

    ul: {
      width: "85%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
    },

    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      width: "90%",
    },
  },
  managingContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%"
  }
}));

function About() {
  const { classes } = TestStyle();
  const { t } = useTranslation("about");

  return (
    <div>
      <Navbar />
      <Intro type="about" size={0} />

      <div className={classes.content}>
        <p>
          <Trans t={t} i18nKey={"content.0" as any}>
            PreI<i>In I</i>Post I
          </Trans>
        </p>
        <p>{t("content.1" as any)}</p>

        <ul>
          <li>{t("content.2" as any)}</li>
          <li>{t("content.3" as any)}</li>
          <li>{t("content.4" as any)}</li>
          <li>{t("content.5" as any)}</li>
        </ul>

        <p>{t("content.6" as any)}</p>
        <p>
          <Trans t={t} i18nKey={"content.7" as any}>
            PreI<i>In I</i>Post I
          </Trans>
        </p>
        <div className={classes.managingContainer}><p>{t("content.8" as any)}<a rel="noreferrer" href="https://be.linkedin.com/in/tanguylacourt" target="_blank">Tanguy Lacourt</a> & <a rel="noreferrer" href="https://www.linkedin.com/in/benoitdescamps" target="_blank">Benoit Descamps</a></p></div>
      </div>

      <Contact />
    </div>
  );
}

export default About;
