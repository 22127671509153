import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import CheckIcon from "@mui/icons-material/Check";

interface Props {
  img: string;
  name: string;
  number: number;
}

const TestStyle = makeStyles({ name: "SideImgListStyle" })((theme) => ({
  sideImgListComponent: {
    display: "flex",
    gap: "3%",
    justifyContent: "center",

    [`@media screen and (min-width:${theme.breakpoints.M})`]: {
      "&:nth-of-type(even)": {
        flexDirection: "row-reverse",
      },
    },

    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      gap: "20px",
      img: {
        width: "100%",
      },
    },
  },

  imgContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    img: {
      width: "100%",
      maxWidth: "500px",
      height: "300px",
    },
  },

  text: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "20px",
    justifyContent: "center",
    h3: {
      fontSize: theme.desktop.FSH3,
      [`@media screen and (max-width:${theme.breakpoints.M})`]: {
        fontSize: theme.mobile.FSH3,
      },
    },


    ul: {
      paddingLeft: "20px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      listStyle: "none",
      li: {
        display:'flex',
      },
    },
  },
}));

function SideImgList(props: Props) {
  const { classes } = TestStyle();
  const { t } = useTranslation("sideImgList");

  function renderList() {
    let listData: JSX.Element[] = [];
    for (
      let i = 0;
      i < t(`${props.name}.${props.number}.content` as any).length;
      i++
    ) {
      listData.push(
        <li key={props.name + "item" + i}>
          <div style={{display:"flex", alignItems:'start'}}>
          <CheckIcon
            sx={{
              color: "green",
              fontSize: "15px",
              margin: "1px 2px",
              verticalAlign: "top",
            }}
          />
          </div>
          
          {t(`${props.name}.${props.number}.content.${i}` as any)}
        </li>
      );
    }
    return listData;
  }

  return (
    <div className={classes.sideImgListComponent}>
      <div className={classes.imgContainer}>
        <img src={props.img} alt={props.name + "Image"} />
      </div>

      <div className={classes.text}>
        <h3>{t(`${props.name}.${props.number}.title` as any)}</h3>
        <ul>{renderList()}</ul>
      </div>
    </div>
  );
}

export default SideImgList;
