import React from "react";
import { makeStyles } from "tss-react/mui";
import Navbar from "../../Components/Navbar/Navbar";
import PortfolioItem from "../../Components/PortfolioItem/PortfolioItem";
import Contact from "../../Components/Contact/Contact";
import { useTranslation } from "react-i18next";

const TestStyle = makeStyles({ name: "PortfolioStyle" })((theme) => ({
  intro: {
    height: "350px",
    width: "100%",
    paddingTop: theme.desktop.NAVBARHEIGHT,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: "30px",
  },

  introTitles: {
    width: "90%",
    h2: {
      fontSize: theme.desktop.FSH2,
    },
    h4: {
      fontSize: theme.desktop.FSH4,
    },
    "h2, h4": {
      color: "black",
    },
    [`@media screen and (max-width:${theme.breakpoints.M})`]: {
      h2: {
        fontSize: theme.mobile.FSH2,
      },
      h4: {
        fontSize: theme.mobile.FSH4,
      },
    },
  },

  portfolioContainer: {
    height: "120vh",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",

    [`@media screen and (max-width:${theme.breakpoints.M}) and (min-width:${theme.breakpoints.S})`]:
      {
        height: "90vh",
      },

    [`@media screen and (max-width:${theme.breakpoints.S})`]: {
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "repeat(5, 1fr)",
    },
  },
}));

function Portfolio() {
  const { classes } = TestStyle();
  const { t } = useTranslation('intro');
  return (
    <div>
      <Navbar />
      <div className={classes.intro}>
        <div className={classes.introTitles}>
          <h2>{t("portfolio.title")}</h2>
          <h4>{t("portfolio.subtitle")}</h4>
        </div>
      </div>

      <div className={classes.portfolioContainer}>
        <PortfolioItem number={0} />
        <PortfolioItem number={1} />
        <PortfolioItem number={2} />
        <PortfolioItem number={3} />
        <PortfolioItem number={4} />
        <PortfolioItem number={5} />
        <PortfolioItem number={6} />
        <PortfolioItem number={7} />
        <PortfolioItem number={8} />
      </div>

      <Contact />
    </div>
  );
}

export default Portfolio;
